



























import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import CrewMemberDocumentCreateDialog from "@/components/dialogs/CrewMemberDocumentCreateDialog.vue";
import CrewMemberDocumentUpdateDialog from "@/components/dialogs/CrewMemberDocumentUpdateDialog.vue";
import CrewMemberDocumentTable from "@/components/crew-member/CrewMemberDocumentTable.vue";
import { CrewMemberDocumentModel } from "@/api/generated";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    CrewMemberDocumentTable,
    CrewMemberDocumentCreateDialog,
    CrewMemberDocumentUpdateDialog
  }
})
export default class CrewMemberDocuments extends Vue {
  @Prop(String) private crewMemberId!: string;
  private selectedCrewMemberDocumentId = "";
  private refreshKey = false;
  private createDialog = false;
  private updateDialog = false;

  private getDocuments() {
    this.refreshKey = !this.refreshKey;
  }

  private onCreateDocument() {
    this.createDialog = true;
  }

  private onEditDocument(document: CrewMemberDocumentModel) {
    if (!document.crewMemberDocumentId) {
      return;
    }

    this.selectedCrewMemberDocumentId = document.crewMemberDocumentId;
    this.updateDialog = true;
  }

  private async onDownloadDocument(document: CrewMemberDocumentModel) {
    if (!document.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      document.documentId
    );

    FileDownloadUtility.DownloadFile(fileResponse.data, document.filename);
  }

  private async onDeleteDocument(document: CrewMemberDocumentModel) {
    if (!document.crewMemberDocumentId) {
      return;
    }

    try {
      await Api.CrewMemberDocumentService.apiCrewmemberdocumentCrewMemberDocumentIdDelete(
        document.crewMemberDocumentId
      );
      snackbarModule.setSnackbarMessage("Document deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete document");
    }

    this.getDocuments();
  }

  private async onArchiveDocument(document: CrewMemberDocumentModel) {
    if (!document.crewMemberDocumentId) {
      return;
    }

    try {
      await Api.CrewMemberDocumentService.apiCrewmemberdocumentArchiveCrewMemberDocumentIdDelete(
        document.crewMemberDocumentId
      );
      snackbarModule.setSnackbarMessage("Document Archived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to archive document");
    }
    this.getDocuments();
  }

  private async onUnarchiveDocument(document: CrewMemberDocumentModel) {
    if (!document.crewMemberDocumentId) {
      return;
    }

    try {
      await Api.CrewMemberDocumentService.apiCrewmemberdocumentUnarchiveCrewMemberDocumentIdDelete(
        document.crewMemberDocumentId
      );
      snackbarModule.setSnackbarMessage("Document Unarchived");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to unarchive document");
    }
    this.getDocuments();
  }
}
